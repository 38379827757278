import React, { useEffect } from 'react'
import HeaderDemo from "../Components/Common/HeaderDemo";
import MesBlog from "../Components/Blogs Detail/MesBlog";
import ScrollToTop from "../Components/Common/ScrollToTop";

const MesMain = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <HeaderDemo />
      <MesBlog />
      <ScrollToTop />
    </div>
  );
};

export default MesMain;
